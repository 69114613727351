<template>
  <div>
    <BaseForm :title="title" :mod="mod" :flat="flat" :divider="divider" :dialog="dialog" :loading="loading" @save="save">
      <template #content>
        <v-expansion-panels v-model="panel" class="pa-4">
          <v-expansion-panel>
            <v-expansion-panel-header> Dados básicos </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome"
                    v-model="form.firstName"
                    class="required"
                    :disabled="form.alreadyMadeTheFirstChange"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    label="Sobrenome"
                    v-model="form.lastName"
                    class="required"
                    :disabled="form.alreadyMadeTheFirstChange"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    label="CPF"
                    v-model="form.taxDocument"
                    class="required"
                    v-mask="'###.###.###-##'"
                    :disabled="form.alreadyMadeTheFirstChange"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="form.dateOfBirth"
                    class="required"
                    :disabled="form.alreadyMadeTheFirstChange"
                    type="date"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="E-Mail" v-model="form.email"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Nova senha" type="password" v-model="form.password"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field label="Confirmar nova senha" type="password" v-model="form.confirmPassword"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Endereço </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-text-field
                    class="required"
                    label="Cep:"
                    v-model="form.addressZipCode"
                    placeholder="xxxxx-xxx"
                    v-mask="'#####-###'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-text-field class="required" label="Logradouro" v-model="form.address" maxlength="254"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field label="Número" v-model="form.addressNumber" class="required"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <v-text-field class="required" label="Bairro" v-model="form.addressDistrict"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field label="Complemento" v-model="form.addressComplement"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col><v-text-field v-model="form.addressCity" label="Cidade:" class="required"> </v-text-field></v-col>
                <v-col>
                  <v-select v-model="form.addressState" :items="brazilStates" label="Estado:" class="required"> </v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successDialog = false">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import { mapState } from "vuex";
import miscellaneous from "@/config/miscellaneous";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    form: {},
    panel: 0,
    successDialog: false,
  }),
  computed: {
    ...mapState("user", ["userData"]),
    brazilStates() {
      const states = miscellaneous.brazilStates.map((s) => ({
        text: s,
        value: s,
      }));
      return states;
    },
  },
  methods: {
    validate() {
      const errors = [...this.validateGeneralData(), ...this.validateAddress()];

      return errors;
    },
    validateGeneralData() {
      const errors = [];

      if (!this.form.taxDocument) errors.push("Informe o CPF");

      if (!this.form.dateOfBirth) errors.push("Informe a data de nascimento");

      if (!this.form.email) errors.push("Informe o novo e-mail");

      if (this.form.password && (!this.form.confirmPassword || this.form.confirmPassword !== this.form.password))
        errors.push("As senhas não conferem");

      return errors;
    },
    validateAddress() {
      const errors = [];

      if (!this.form.addressZipCode) errors.push("Informe o CEP do endereço");

      if (!this.form.address) errors.push("Informe o logradouro do endereço");

      if (!this.form.addressNumber) errors.push("Informe o número do endereço");

      if (!this.form.addressDistrict) errors.push("Informe o bairro do endereço");

      if (!this.form.addressCity) errors.push("Informe a cidade do endereço");

      if (!this.form.addressState) errors.push("Informe o estado do endereço");

      return errors;
    },
    async save() {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.updateProfile();
      }
    },
    async updateProfile() {
      try {
        this.loading = true;

        await this.$axios.put(`/customers/profile`, this.form);

        this.form.alreadyMadeTheFirstChange = true;

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async loadUserData() {
      try {
        this.loading = true;

        const response = await this.$axios.get("/customers/profile");

        this.form = { ...response.data };
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
  },
  created() {
    this.loadUserData();
  },
};
</script>

<style></style>
